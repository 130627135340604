import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    treatments: {
      loading: false,
      data: [],
    },
    treatmentAvailability: {
      loading: false,
      data: {
        slots: [],
      },
    },
    bookingRequests: {
      loading: false,
      pagination: {
        total: 0,
        page: 0,
        itemsPerPage: 0,
        lastPage: null,
        data: [],
      },
    },
    verifyBookingRequestLoading: false,
    createBookingRequestLoading: false,
    acceptBookingRequestLoading: null,
    rejectBookingRequestLoading: null,
    pendingBookingRequestsCount: 0,
  },
  mutations: {
    SET_TREATMENTS_LOADING: function (state, payload) {
      state.treatments.loading = payload;
    },
    SET_TREATMENTS_DATA: function (state, payload) {
      state.treatments.data = payload;
    },
    SET_TREATMENT_AVAILABILITY_LOADING: function (state, payload) {
      state.treatmentAvailability.loading = payload;
    },
    SET_TREATMENT_AVAILABILITY_DATA: function (state, payload) {
      state.treatmentAvailability.data = payload;
    },
    SET_VERIFY_BOOKING_REQUEST_LOADING: function (state, payload) {
      state.verifyBookingRequestLoading = payload;
    },
    SET_CREATE_BOOKING_REQUEST_LOADING: function (state, payload) {
      state.createBookingRequestLoading = payload;
    },
    SET_BOOKING_REQUESTS_LOADING: function (state, payload) {
      state.bookingRequests.loading = payload;
    },
    SET_BOOKING_REQUESTS_PAGINATION: function (state, payload) {
      state.bookingRequests.pagination = payload;
    },
    SET_ACCEPT_BOOKING_REQUEST_LOADING: function (state, payload) {
      state.acceptBookingRequestLoading = payload;
    },
    SET_REJECT_BOOKING_REQUEST_LOADING: function (state, payload) {
      state.rejectBookingRequestLoading = payload;
    },
    SET_PENDING_BOOKING_REQUESTS_COUNT: function (state, payload) {
      state.pendingBookingRequestsCount = payload;
    },
  },
  actions: {
    getTreatments: function ({ commit }) {
      const vm = this;
      commit("SET_TREATMENTS_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`booking-requests/treatments`).then(
          (res) => {
            commit("SET_TREATMENTS_DATA", res.data.data);
            commit("SET_TREATMENTS_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_TREATMENTS_LOADING", false);
            Swal.fire({
              title: "Error",
              text:
                err?.data?.error?.message ||
                err?.data?.message ||
                "Something went wrong...",
              icon: "error",
            });
            resolve(false);
          }
        );
      });
    },
    getTreatmentAvailability: function ({ commit }, payload) {
      const vm = this;
      commit("SET_TREATMENT_AVAILABILITY_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http
          .get(
            `treatments/${payload.treatmentId}/availability?start=${payload.start}&end=${payload.end}`
          )
          .then(
            (res) => {
              commit("SET_TREATMENT_AVAILABILITY_DATA", res.data.data);
              commit("SET_TREATMENT_AVAILABILITY_LOADING", false);
              resolve(res.data);
            },
            (err) => {
              commit("SET_TREATMENT_AVAILABILITY_LOADING", false);
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    verifyBookingRequest: function ({ commit }, payload) {
      const vm = this;
      commit("SET_VERIFY_BOOKING_REQUEST_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.post(`verify-booking-request`, payload).then(
          () => {
            commit("SET_VERIFY_BOOKING_REQUEST_LOADING", false);
            resolve(true);
          },
          (err) => {
            commit("SET_VERIFY_BOOKING_REQUEST_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createBookingRequest: function ({ commit }, payload) {
      const vm = this;
      commit("SET_CREATE_BOOKING_REQUEST_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.post(`booking-requests`, payload).then(
          () => {
            commit("SET_CREATE_BOOKING_REQUEST_LOADING", false);
            resolve(true);
          },
          (err) => {
            commit("SET_CREATE_BOOKING_REQUEST_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Oops!",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getBookingRequests: function ({ state, commit }, payload) {
      const vm = this;
      if (payload && payload.page == 1) {
        commit("SET_BOOKING_REQUESTS_LOADING", true);
      }
      const filters = [];
      if (payload) {
        if (payload.limit) {
          filters.push(`limit=${payload.limit}`);
        }
        if (payload.page) {
          filters.push(`page=${payload.page}`);
        }
        if (payload.status) {
          filters.push(`status=${payload.status}`);
        }
        if (payload.treatmentId) {
          filters.push(`treatment_id=${payload.treatmentId}`);
        }
        if (payload.serviceId) {
          filters.push(`service_id=${payload.serviceId}`);
        }
        if (payload.paymentStatus) {
          filters.push(`payment_status=${payload.paymentStatus}`);
        }
      }

      return new Promise((resolve) => {
        return vm.$http.get(`booking-requests?${filters.join("&")}`).then(
          (res) => {
            if (payload.limit) {
              if (payload.page == 1) {
                commit("SET_BOOKING_REQUESTS_PAGINATION", {
                  total: res.data.meta.total,
                  page: res.data.meta.current_page,
                  itemsPerPage: res.data.meta.per_page,
                  lastPage: res.data.meta.last_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_BOOKING_REQUESTS_PAGINATION", {
                  total: res.data.meta.total,
                  page: res.data.meta.current_page,
                  itemsPerPage: res.data.meta.per_page,
                  lastPage: res.data.meta.last_page,
                  data: [
                    ...state.bookingRequests.pagination.data,
                    ...res.data.data,
                  ],
                });
              }
            } else {
              commit("SET_BOOKING_REQUESTS_PAGINATION", {
                total: 0,
                page: 0,
                itemsPerPage: 0,
                lastPage: null,
                data: res.data.data,
              });
            }
            commit("SET_BOOKING_REQUESTS_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_BOOKING_REQUESTS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getBookingRequestById: function (_, id) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`booking-requests/${id}`).then(
          (res) => {
            resolve(res.data.data);
          },
          (err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    acceptBookingRequest: function ({ state, commit }, payload) {
      const vm = this;
      commit("SET_ACCEPT_BOOKING_REQUEST_LOADING", payload.bookingRequestId);
      return new Promise((resolve) => {
        return vm.$http
          .post(`booking-requests/${payload.bookingRequestId}/accept`, payload)
          .then(
            (res) => {
              const bookingRequests = state.bookingRequests.pagination.data;
              const bookingRequestIndex = bookingRequests.findIndex(
                (bookingRequest) =>
                  bookingRequest.id == payload.bookingRequestId
              );
              if (bookingRequestIndex > -1) {
                bookingRequests[bookingRequestIndex] = res.data.data;
                commit("SET_BOOKING_REQUESTS_PAGINATION", {
                  ...state.bookingRequests.pagination,
                  data: bookingRequests,
                });
                commit(
                  "SET_PENDING_BOOKING_REQUESTS_COUNT",
                  state.pendingBookingRequestsCount - 1
                );
              }
              commit("SET_ACCEPT_BOOKING_REQUEST_LOADING", null);
              resolve(true);
            },
            (err) => {
              commit("SET_ACCEPT_BOOKING_REQUEST_LOADING", null);
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    rejectBookingRequest: function ({ state, commit }, payload) {
      const vm = this;
      commit("SET_REJECT_BOOKING_REQUEST_LOADING", payload.bookingRequestId);
      return new Promise((resolve) => {
        return vm.$http
          .post(`booking-requests/${payload.bookingRequestId}/reject`, payload)
          .then(
            (res) => {
              const bookingRequests = state.bookingRequests.pagination.data;
              const bookingRequestIndex = bookingRequests.findIndex(
                (bookingRequest) =>
                  bookingRequest.id == payload.bookingRequestId
              );
              if (bookingRequestIndex > -1) {
                bookingRequests[bookingRequestIndex] = res.data.data;
                commit("SET_BOOKING_REQUESTS_PAGINATION", {
                  ...state.bookingRequests.pagination,
                  data: bookingRequests,
                });
                commit(
                  "SET_PENDING_BOOKING_REQUESTS_COUNT",
                  state.pendingBookingRequestsCount - 1
                );
              }
              commit("SET_REJECT_BOOKING_REQUEST_LOADING", null);
              resolve(true);
            },
            (err) => {
              commit("SET_REJECT_BOOKING_REQUEST_LOADING", null);
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text:
                    err?.data?.error?.message ||
                    err?.data?.message ||
                    "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    getPendingBookingRequestsCount: function ({ commit }) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`booking-requests/pending-count`).then(
          (res) => {
            commit("SET_PENDING_BOOKING_REQUESTS_COUNT", res.data.count);
            resolve(res.data);
          },
          () => {
            resolve(false);
          }
        );
      });
    },
    updatePendingBookingRequestsCount: function ({ state, commit }, count) {
      commit(
        "SET_PENDING_BOOKING_REQUESTS_COUNT",
        Math.max(0, state.pendingBookingRequestsCount + count)
      );
    },
  },
};
